import ReactOnRails from 'react-on-rails'

import AddToCart from '../components/AddToCart'
import AlertSubscriptionForm from '../components/AlertSubscriptionForm'
import Cart from '../components/Cart'
import ContactUsForm from '../components/ContactUsForm'
import ProductLightbox from '../components/ProductLightbox'

import 'yet-another-react-lightbox/styles.css'

ReactOnRails.register({
  AddToCart,
  AlertSubscriptionForm,
  Cart,
  ContactUsForm,
  ProductLightbox,
})
