import React, { useState, useRef } from 'react'
import axios from 'axios'

const AlertSubscriptionForm = ({
  method,
  url,
  token,
  subscription,
  categories,
}) => {
  const toastRef = useRef(null)
  const [toastMessage, setToastMessage] = useState('')

  const showToast = () => {
    bootstrap.Toast.getOrCreateInstance(toastRef.current).show()
  }

  const validation = {
    'name': null,
    'email': null,
  }

  for (const key in validation) {
    const [validationClass, setValidationClass] = useState('')
    const [feedbackClass, setFeedbackClass] = useState('valid-feedback')
    const [feedbackMessage, setFeedbackMessage] = useState('')

    validation[key] = {
      validationClass, setValidationClass,
      feedbackClass, setFeedbackClass,
      feedbackMessage, setFeedbackMessage,
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()
    const form = event.target
    const headers = { 'Accept': 'application/json' }
    const formData = new FormData(form)

    formData.set('authenticity_token', token)
    axios.request({ method, url, headers, data: formData })
      .then((response) => {
        for (const key in validation) {
          const { setValidationClass } = validation[key]
          setValidationClass('is-valid')
        }
        setToastMessage('Preferințele au fost actualizate')
        showToast()
      })
      .catch((error) => {
        if (!error.response) {
          console.error(error)
          return
        }
        const { status, data } = error.response

        if (status !== 422) {
          console.error(error)
          return
        }
        for (const key in validation) {
          const {
            setValidationClass,
            setFeedbackClass,
            setFeedbackMessage,
          } = validation[key]

          if (key in data) {
            setValidationClass('is-invalid')
            setFeedbackClass('invalid-feedback')
            setFeedbackMessage(data[key][0])
          } else {
            setValidationClass('is-valid')
          }
        }
      })
  }

  const isChecked = (id) => (subscription.categories || []).includes(id)

  return (
    <form onSubmit={onSubmit}>
      <div className="row mb-3">
        <label className="col-sm-2 col-form-label">
          Nume
          <span className="text-danger">*</span>
        </label>
        <div className="col-sm-10">
          <input
            name="alert_subscription[name]"
            type="text"
            defaultValue={subscription.name}
            className={`form-control ${validation.name.validationClass}`}
          />
          <div className={validation.name.feedbackClass}>
            {validation.name.feedbackMessage}
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-2 col-form-label">
          Email
          <span className="text-danger">*</span>
        </label>
        <div className="col-sm-10">
          <input
            name="alert_subscription[email]"
            type="email"
            defaultValue={subscription.email}
            className={`form-control ${validation.email.validationClass}`}
          />
          <div className={validation.email.feedbackClass}>
            {validation.email.feedbackMessage}
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-2 col-form-label">
          Categorii
        </label>
        <div className="col-sm-10">
          <div className="row">
            {categories.map(category => (
              <div key={category.id} className="col-6 col-lg-4">
                <label className="form-check-label mb-2">
                  <input
                    type="checkbox"
                    name="alert_subscription[categories][]"
                    className="form-check-input me-2"
                    value={category.id}
                    defaultChecked={isChecked(category.id)}
                  />
                  {category.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="row justify-content-center mb-5">
        <div className="col-sm-10 text-center">
          <p className="fw-medium mt-4">
            Prin completarea acestui formular, ești de acord să primești
            notificări privind produsele noi. Poți să te dezabonezi în orice
            moment.
          </p>
          <button type="submit" className="btn btn-dark px-5">
            Confirmă
          </button>
          <div
            className="toast-container position-fixed end-0 p-3"
            style={{ top: '50px' }}
          >
            <div
              ref={toastRef}
              className="toast align-items-center"
              role="alert"
              style={{
                width: '250px',
                color: '#0f5132',
                backgroundColor: '#d1e7dd',
              }}
            >
              <div className="d-flex">
                <div className="toast-body ms-auto">
                  {toastMessage}
                </div>
                <button
                  type="button"
                  className="btn-close me-2 m-auto"
                  data-bs-dismiss="toast"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default AlertSubscriptionForm
